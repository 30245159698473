import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"

const ProjectTemplate = ({ pageContext: { title }, data }) => {
  return (
    <>
      <Seo
        title={data.sanityWork.title.toUpperCase()}
        image={data.sanityWork.mainImage.asset.url}
      />
      <main class="project-template-page">
        <h2>{title}</h2>
        {/* <p>{data.sanityWork.body}</p> */}

        <p className="project-desc">
          {data.sanityWork.body.map(item => {
            return (
              <span key={item._key}>
                {item.children.map(item2 => {
                  return <span key={item2._key}>{item2.text}</span>
                })}
              </span>
            )
          })}
        </p>
      </main>
    </>
  )
}

export const query = graphql`
  query getSingleProject($title: String) {
    sanityWork(title: { eq: $title }) {
      title
      body {
        children {
          text
        }
      }
      mainImage {
        asset {
          url
        }
      }
    }
  }
`

export default ProjectTemplate
